import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Product from "../components/product"

const ShopPageStyles = styled.div`
  h1 {
    font-family: "sf-intellivised",Arial,Helvetica,sans-serif;
    font-weight: 700;
    font-style: italic, oblique;
    font-size: 4rem;
    text-align: center;
    margin-top: 4rem;
  }

  .products-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 3em 1em;
    max-width: 1350px;
    margin: 0 auto;
  }

  .empty-shop-message {
    line-height: 1.25;
    width: 700px;
    max-width: 95%;
    margin: 0 auto;
    padding: 2em 0;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }

  @media (max-width: 800px) {
    article {
      margin: 0 auto 1em;
    }
  }
`

export default function ShopPage({ data, location }) {
  const siteTitle = "Shop"
  const pageData = data.allShopifyProduct.edges

  // Pull priority items to the top of the shop
  pageData.sort((a, b) => {
    let fa = a.node.tags.filter(t => t === "priority").length
    let fb = b.node.tags.filter(t => t === "priority").length
    return fb - fa;
  })

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={siteTitle} />
      <ShopPageStyles>
        <h1>Handmade in the USA</h1>
        <div className="products-container">
          {pageData.map(edge => (
            <Product product={edge.node} key={edge.node.id} />
          ))}
          {pageData.length <= 0 && (
            <div className="empty-shop-message">
              <h2>Stay Tuned!</h2>
              <p>We are currently in the process of stocking our shop. Stay tuned as we will have our products available soon!</p>
            </div>
          )}
        </div>
      </ShopPageStyles>
    </Layout>
  )
}

export const query = graphql`
query ShopPageQuery {
  allShopifyProduct(filter: { vendor: { in: ["Forward Motion Technologies", "All"] } })  {
    edges {
    	node {
        id
        handle
        title
        tags
        description
        descriptionHtml
        variants {
          sku
          id
          title
          price
        }
        media {
          preview {
            image {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
        }
      }
    }
  }
}
`